module.exports = {
  title: "Thomas Willson's Blog", // Required
  author: 'Thomas Willson', // Required
  description: 'Software Engineering Project Blog',
  primaryColor: '#3498db', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: 'http://thomasewillson.com',
    github: 'https://github.com/willson556',
    linkedin: 'https://www.linkedin.com/in/willsonthomas/',
  },
  pathPrefix: '/',
  siteUrl: 'https://dev.thomasewillson.com',
};
